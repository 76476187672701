// src/components/Experience.js
import React from 'react';

function Experience() {
  return (
    <section>
      <h2>SKILLS AND EXPERIENCE</h2>
      <p>
      <ul>
  <li><strong>Cloud Platforms:</strong> Google Cloud Platform (GCP), AWS, Azure</li>
  <li><strong>Containerization & Orchestration:</strong> Kubernetes Engine, Docker</li>
  <li><strong>CI/CD Systems:</strong> Jenkins, GitLab CI/CD</li>
  <li><strong>Automation & Scripting:</strong> Bash, Python, Ansible</li>
  <li><strong>Monitoring & Observability:</strong> Prometheus, Grafana, Loki</li>
  <li><strong>Web Servers & Databases:</strong> Apache, Nginx, MySQL, MariaDB, Postgres, PHP</li>
  <li><strong>Security & Compliance:</strong> System/Application Security Patches, Compliance Standards, Best Practices</li>
  <li><strong>Troubleshooting & Support:</strong> Critical Thinking, Problem-Solving, Client Communication</li>
  <li><strong>Collaboration:</strong> Cross-functional Teamwork, Documentation, Feedback Integration</li>
  <li><strong>Professional Communication:</strong> Strong documentation, problem-solving, and customer interaction skills</li>
  <li><strong>Process Improvement:</strong> Ability to recommend and implement process enhancements</li>
  <li><strong>Languages:</strong> English (Advanced), French (Conversational)</li>
</ul>

</p>
    </section>
  );
}

export default Experience;
