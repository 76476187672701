// src/components/Contact.js
import React from 'react';

function Contact() {
  return (
    <section>
      <h2>PROFESSIONAL EXPERIENCE </h2>
      <h4>Defend Canada</h4>
      <p>System Administrator                                                                                    (Nov 2023 - Present)   </p>
      <ul>
  <li>Built and managed CI/CD pipelines, reducing deployment times by 30% and ensuring a continuous flow of updates into source control for development teams.</li>
  <li>Led the scaling and maintenance of infrastructure on Google Kubernetes Engine, improving system uptime by 20% and ensuring seamless client experiences.</li>
  <li>Developed automation scripts using Bash and Python, which increased operational efficiency and reduced manual intervention by 40%.</li>
  <li>Implemented security patches and compliance standards across cloud environments, reducing vulnerabilities by 25%.</li>
  <li>Provided on-call support for critical issues, resolving 95% of incidents within SLA, and maintaining high client satisfaction levels.</li>
  <li>Configured monitoring systems using Prometheus and Grafana, which improved visibility into system performance and reduced downtime by 15%.</li>
</ul>
<h4>McJad Consults  UK</h4>
      <p>Devops Engineer                                                                                     (Nov Jan 2021 - Nov 2023)   </p>
      <ul>
  <li>Managed cloud infrastructure on AWS and Azure, optimizing resource utilization and reducing cloud costs by 15%.</li>
  <li>Automated deployment processes using Jenkins and GitLab CI/CD, which accelerated release cycles and improved code quality.</li>
  <li>Supported Docker-based environments, ensuring stability and scalability of applications across multiple cloud platforms.
  </li>
  <li>Collaborated with development teams to troubleshoot deployment issues, reducing error rates by 20%.</li>
  <li>Documented network environments and provided clear, detailed reports on infrastructure performance, facilitating smoother operations and knowledge transfer.</li>
</ul>

<h4>ADVANS Nigeria Bank</h4>
      <p>System Administrator                                                                                    (Feb 2015 - Jan 2021)   </p>
      <ul>
  <li>Maintained and secured web servers (Apache, Nginx) and databases (MySQL, Postgres), achieving a 99.9% uptime across all systems.
  </li>
  <li>Conducted regular security audits and applied patches, enhancing the security posture of the organization’s IT infrastructure.
  </li>
  <li>Responded promptly to support requests, achieving a 90% resolution rate within the first 24 hours.</li>
  <li>Played a key role in scaling infrastructure to accommodate growing business needs, resulting in a 25% increase in system capacity.</li>
  <li>Supported the installation and configuration of VMware, Hyper-V, and Veeam technologies for clients.</li>
  <li>Provided training and support to customers, helping them to understand and utilize cloud and backup solutions effectively.</li>
  <li>Advised users on appropriate procedures, promoting user education and empowerment.
  </li>
  <li>Identified and escalated situations requiring urgent attention to the appropriate resources.
  </li>
  <li>Redirected problems to the correct resource and tracked and routed problems and requests for documentation.
  </li>
</ul>
<div class="education-section">
  <h2>Education & Training</h2>
  <p>AWS Certified Solutions Architect – Associate</p>
  <p>Microsoft Certified: Azure Fundamentals</p>
  <p>Certified in Cybersecurity (ISC)²</p>
  <p>Obafemi Awolowo University</p>
</div>
    </section>
  );
}

export default Contact;