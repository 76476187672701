// src/components/About.js
import React from 'react';

function About() {
  return (
    <section>
      <h2>PROFESSIONAL SUMMARY</h2>
      <p>Experienced Cloud Systems Administrator with over 5 years of expertise in managing and optimizing large-scale cloud infrastructures, particularly on Google Cloud Platform (GCP) and Kubernetes Engine. Adept at designing and maintaining CI/CD pipelines, automating processes, and implementing security best practices. Strong troubleshooting skills and a proactive approach to improving system efficiency and reliability. Passionate about staying updated with the latest industry trends and delivering top-tier support to enhance client experiences.</p>
    </section>
  );
}

export default About;
