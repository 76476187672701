import React from "react";

function Projects() {
  return (
    <section>
      <h2>Projects</h2>
      <p>
        <i>
          <h3>
            Google Kubernetes Engine (GKE) Infrastructure Scaling and
            Optimization
          </h3>
        </i>
        Spearheaded the scaling and optimization of infrastructure on Google
        Kubernetes Engine (GKE) to support high-traffic client websites and
        applications. This project involved designing and implementing
        auto-scaling policies, optimizing resource allocation, and ensuring high
        availability. By enhancing the infrastructure’s scalability and
        reliability, client satisfaction increased by 20%, and the system
        downtime was reduced by 30%.
      </p>
      <p>
        <h3><i>Client Infrastructure Migration to GCP</i></h3> Led a project to migrate
        a client’s large-scale infrastructure from an on-premises data center to
        Google Cloud Platform (GCP). This involved planning, testing, and
        executing the migration of applications and databases while ensuring
        minimal downtime and data integrity. The successful migration improved
        the client’s operational efficiency by 50% and reduced infrastructure
        costs by 20%.
      </p>
    </section>
  );
}

export default Projects;
